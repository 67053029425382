import React, { Component } from 'react'
import Swiper from 'react-id-swiper'
import 'react-id-swiper/lib/styles/css/swiper.css'

import campaignSlide01 from '../../images/psaslide-01-min.jpg'
import campaignSlide02 from '../../images/psaslide-02-min.jpg'
// import campaignSlide03 from '../../images/psaslide-03-min.jpg'
// import campaignSlide04 from '../../images/psaslide-04-min.jpg'
// import campaignSlide05 from '../../images/psaslide-05-min.jpg'
// import campaignSlide06 from '../../images/psaslide-06-min.jpg'
// import campaignSlide07 from '../../images/psaslide-07-min.jpg'
// import campaignSlide08 from '../../images/psaslide-08-min.jpg'
import campaignSlide09 from '../../images/psaslide-09-min.jpg'
// import campaignSlide10 from '../../images/psaslide-10-min.jpg'
// import campaignSlide11 from '../../images/psaslide-11-min.jpg'
// import campaignSlide12 from '../../images/psaslide-12-min.jpg'
import campaignSlide13 from '../../images/psaslide-13-min.jpg'
import campaignSlide14 from '../../images/psaslide-14-min.jpg'
// import campaignSlide15 from '../../images/psaslide-15-min.jpg'
// import campaignSlide16 from '../../images/psaslide-16-min.jpg'
// import campaignSlide17 from '../../images/psaslide-17-min.jpg'
// import campaignSlide18 from '../../images/psaslide-18-min.jpg'
import campaignSlide19 from '../../images/psaslide-19-min.jpg'

export default class CampaignsSlider extends Component {
	render() {
		const params = {
			loop: true,
			effect: 'fade',
			pagination: {
				el: '.swiper-pagination',
			},
			clickable: true,
			autoplay: {
				delay: 2500,
				disableOnInteraction: false,
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		}

		return (
			<Swiper {...params}>
				<div>
					<img
						src={campaignSlide01}
						className="img-fluid"
						alt="campaignSlide"
					/>
				</div>
				<div>
					<img
						src={campaignSlide02}
						className="img-fluid"
						alt="campaignSlide"
					/>
				</div>

				<div>
					<img
						src={campaignSlide09}
						className="img-fluid"
						alt="campaignSlide"
					/>
				</div>

				<div>
					<img
						src={campaignSlide13}
						className="img-fluid"
						alt="campaignSlide"
					/>
				</div>
				<div>
					<img
						src={campaignSlide14}
						className="img-fluid"
						alt="campaignSlide"
					/>
				</div>

				<div>
					<img
						src={campaignSlide19}
						className="img-fluid"
						alt="campaignSlide"
					/>
				</div>

				{/* 
				<div>
					<img
						src={campaignSlide11}
						className="img-fluid"
						alt="campaignSlide"
					/>
				</div>
				<div>
					<img
						src={campaignSlide03}
						className="img-fluid"
						alt="campaignSlide"
					/>
				</div>
				<div>
					<img
						src={campaignSlide04}
						className="img-fluid"
						alt="campaignSlide"
					/>
				</div>
				<div>
					<img
						src={campaignSlide05}
						className="img-fluid"
						alt="campaignSlide"
					/>
				</div>
				<div>
					<img
						src={campaignSlide06}
						className="img-fluid"
						alt="campaignSlide"
					/>
				</div>
						<div>
					<img
						src={campaignSlide07}
						className="img-fluid"
						alt="campaignSlide"
					/>
				</div>
				<div>
					<img
						src={campaignSlide08}
						className="img-fluid"
						alt="campaignSlide"
					/>
				</div>		
					<div>
					<img
						src={campaignSlide10}
						className="img-fluid"
						alt="campaignSlide"
					/>
				</div><div>
					<img
						src={campaignSlide12}
						className="img-fluid"
						alt="campaignSlide"
					/>
				</div><div>
					<img
						src={campaignSlide15}
						className="img-fluid"
						alt="campaignSlide"
					/>
				</div><div>
					<img
						src={campaignSlide16}
						className="img-fluid"
						alt="campaignSlide"
					/>
				</div><div>
					<img
						src={campaignSlide17}
						className="img-fluid"
						alt="campaignSlide"
					/>
				</div><div>
          <img
            src={campaignSlide18}
            className="img-fluid"
            alt="campaignSlide"
          />
        </div>*/}
			</Swiper>
		)
	}
}
