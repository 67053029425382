import React from 'react'
import Layout from '../layouts'
// import { Table } from 'reactstrap';
import Contact from '../components/contact/contact.js'
import CampaignSlider from '../components/hero/campaignsSlider.js'
class PSAPage extends React.Component {
	render() {
		return (
			<Layout head={{ title: 'Customized PSA Campaigns' }}>
				<section id="lunchtruck" className="text-center">
					<div className="container">
						<div className="row">
							<div className="col">
								<h1 className="section-title">Customized PSA Campaigns</h1>
								<p className="section-p">
									Trimex Outdoor has over 30 years experience handling important
									State and Federal PSA Campaigns at the National, Regional and
									Local levels. From the U.S. Census (for 30 years) to more
									recently, COVID-19, our Lunch Trucks, Place-Based Networks,
									and Marina & Waterway postings (in California), have provided
									an OOH presence exactly where PSA messages most need to be!
								</p>
								<CampaignSlider />
							</div>
						</div>
					</div>
				</section>
				<Contact />
			</Layout>
		)
	}
}
export default PSAPage
